// extracted by mini-css-extract-plugin
export var heading = "businessHours-module--heading--imCRM index-module--c-heading-1--Ya186 default-module--font-bold--5oEvQ";
export var container = "businessHours-module--container--CDIU0 default-module--container--7cp5M";
export var tableWrapper = "businessHours-module--table-wrapper--7ayEn";
export var table = "businessHours-module--table--LdIKt";
export var tableHead = "businessHours-module--table-head--tbydq";
export var tableHeadText = "businessHours-module--table-head-text--Bcoht";
export var tableBody = "businessHours-module--table-body--zV48j";
export var colNarrow = "businessHours-module--col-narrow--q9IRW";
export var colHours = "businessHours-module--col-hours--heWms";
export var colDays = "businessHours-module--col-days--XKLBP";
export var space = "businessHours-module--space--U6PnJ";
export var narrow = "businessHours-module--narrow--EblP4";
export var hours = "businessHours-module--hours--q0DcP";
export var note = "businessHours-module--note--yuFet";