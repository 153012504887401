import React from 'react'
import * as mapStyle from "./Map.module.css"

export default function Map() {
    return (
        <div className={mapStyle.container}>
            <div className={mapStyle.iframeWrapper}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.339801602927!2d140.01897945172865!3d35.693254836820984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x602281e181db8af5%3A0x26a16ffb12cb416f!2z5bmz5a6J5aCCIOOBk-OBk-OCjeOBruOCr-ODquODi-ODg-OCrw!5e0!3m2!1sja!2sjp!4v1623731471589!5m2!1sja!2sjp" title="Google Map" width="600" height="360" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
            </div>
            <div className={mapStyle.caption}>
                <p>千葉県船橋市前原西２-11-11 平安堂医療ビル4F　<br className="visible-xs" />TEL. 047-407-3819　FAX. 047-407-3820</p>
                <ul className={mapStyle.list}>
                    <li className={mapStyle.listItem + ' hidden-xs'}>JR津田沼駅北口より 徒歩3分、新京成線 新津田沼駅より 徒歩4分</li>
                    <li className={mapStyle.listItem + ' visible-xs'}>JR津田沼駅北口より 徒歩3分</li>
                    <li className={mapStyle.listItem + ' visible-xs'}>新京成線 新津田沼駅より 徒歩4分</li>
                </ul>
            </div>
            <div className={mapStyle.textBlock}>
                <h3 className={mapStyle.smallHeading}>契約駐車場のご案内</h3>
                <p>平安堂医療ビル正面向かって右側のコインパーキング「プリーズパーク津田沼第一駐車場」が契約駐車場です。<br className='hidden-xs' />番号1〜9の空いている場所はどこでも結構です。駐車証明書を入手してから受診してください。</p>
            </div>
        </div>
    )
}
