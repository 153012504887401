import {React} from "react"
import { graphql } from "gatsby"
import Layout from "/src/layout/default"
import Meta from "/src/components/Meta"
import BusinessHours from "/src/components/BusinessHours"
import Map from "/src/components/Map"
import * as style from "./access.module.css"

const AccessPage = ({data, location}) => {
  return (
    <Layout location={location}>
      <Meta title="診療時間・アクセス | 平安堂こころのクリニック" description="平安堂こころのクリニックは、津田沼駅の心療内科・精神科・児童精神科です。専門外来に、子どもの発達外来、強迫症外来を設けています。患者さまに安心して帰っていただけるこころ安らぐクリニックを目指しています。" >
        <meta name="format-detection" content="telephone=no" />
      </ Meta>
      <div className={style.container}>
        <h1 className="page-title"><span className="page-title-inner">診療時間・アクセス</span></h1>

        <div className={style.businessHours}>
          <BusinessHours data={data.microcmsBusinessHours} />
        </div>
        <Map />
      </div>
    </Layout>
  )
}

export default AccessPage

export const query = graphql`
  query {
    microcmsBusinessHours {
      id
      hours {
        day
        fieldId
        hours
      }
    }
  }
`