import React from 'react'
import * as tableStyle from "./businessHours.module.css"

export default function BusinessHours(props) {
    return (
        <div className={tableStyle.container}>
            <div className={tableStyle.tableWrapper}>

                <table className={tableStyle.table}>
                    <colgroup>
                        <col className={tableStyle.colNarrow} />
                        <col className={tableStyle.colHours} />
                        <col className={tableStyle.colDays} />
                        <col className={tableStyle.colDays} />
                        <col className={tableStyle.colDays} />
                        <col className={tableStyle.colDays} />
                        <col className={tableStyle.colDays} />
                        <col className={tableStyle.colDays} />
                        <col className={tableStyle.colDays} />
                    </colgroup>
                    <thead className={tableStyle.tableHead}>
                        <tr>
                        <td colSpan="2" className={tableStyle.space}></td>
                        {
                            props.data.hours.map((ele, i) => {
                            return <th className="" key={ele.day[0]}><span className={tableStyle.tableHeadText}>{ele.day[0]}</span></th>
                            })
                        }
                        </tr>
                    </thead>
                    <tbody className={tableStyle.tableBody}>
                        <tr>
                        <th className={tableStyle.narrow}>午前</th>
                        <th className={tableStyle.hours}>&ensp;&ensp;9:00ー12:00</th>
                        {
                            props.data.hours.map((ele, i) => {
                            const isOpen = ['9時〜12時','9時〜17時','9時〜17時30分'].includes(ele.hours[0]) ? '○' : 'ー'
                            return <td className="" key={i}>{isOpen}</td>
                            })
                        }
                        </tr>
                        <tr>
                        <th className={tableStyle.narrow} rowSpan="2">午後</th>
                        <th className={tableStyle.hours}>14:00ー17:00</th>
                        {
                            props.data.hours.map((ele, i) => {
                            const rowSpan = ['9時〜12時','休診日'].includes(ele.hours[0]) ? '2' : '1'
                            const isOpen  = ['9時〜17時','12時〜17時'].includes(ele.hours[0]) ? '○' : 'ー'
                            return <td className="" rowSpan={rowSpan} key={i}>{isOpen}</td>
                            })
                        }
                        </tr>
                        <tr>
                        <th className={tableStyle.hours}>14:00ー17:30</th>
                        {
                            props.data.hours.map((ele, i) => {
                            if(['9時〜12時','休診日'].includes(ele.hours[0])) {
                                return null
                            }
                            const isOpen  = ['9時〜17時30分','12時〜17時30分'].includes(ele.hours[0]) ? '○' : 'ー'
                            return <td className="" key={i}>{isOpen}</td>
                            })
                        }
                        </tr>
                    </tbody>
                </table>
            </div>
            <p className={tableStyle.note}>［休診日］ 水曜／土曜／日曜・祝祭日</p>
        </div>
    )
}
